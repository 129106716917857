<template>
  <b-card>
    <h1 class="mb-2">Testear reglas de tickets</h1>
    <form-render :fields="fields" containerButtonsClass="col-md-4 col-xl-3 mt-2 mb-1 d-flex align-items-center"
      :form.sync="form" ref="formProveRules" :key="keyFormRender" @send="tryproverule" :disabled="loading.first">
      <template #cleanfilters>
        <b-button variant="warning" class="ml-2 width:100% mt-2" v-b-tooltip.hover title="Buscar" @click="ok()"
          :disabled="loading.first || loading.second"><feather-icon icon="SearchIcon" /></b-button>
        <b-button variant="outline-light" class="ml-2 width:100% mt-2" v-b-tooltip.hover title="Limpiar búsqueda"
          @click="cleanFilter" :disabled="loading.first || loading.second"><feather-icon
            icon="RefreshCwIcon" /></b-button>
      </template>
    </form-render>
    <h4 class="mt-2"><strong>Resultado:</strong> {{ msgRule }}
      <template v-if="msgRule">
        <b-icon v-if="msgRule === 'La regla si aplica para este envío'" variant="success" icon="check-circle"
          font-scale="1"></b-icon>
        <b-icon v-else icon="x-circle" variant="danger" font-scale="1"></b-icon>
      </template>
    </h4>

    <p class="mt-1"><strong>ID envío:</strong> {{ delivery }}</p>
    <p class="mt-1"><strong>ID regla :</strong> {{ rule }}</p>
    <p class="mt-1"><strong>Tipo de regla:</strong> {{ typeOfRule2 }} </p>
    <p class="mt-1"><strong>Tipo de ejecución:</strong> {{ typeOfRule }}</p>
    <table-render id="resultRule" :rows.sync="rows" :schema="schema">
      <template #values="scope">
        <ul>
          <li v-for="(value, index) in scope.rowdata.values" :key="index">
            {{ value }}
          </li>
        </ul>
      </template>
      <template #verify="scope">
        <ul>
          <li>
            {{ scope.rowdata.verify }}
          </li>
        </ul>
      </template>
      <template #verifyValue="scope">
        <b-icon v-if="scope.rowdata.verifyValue" variant="success" icon="check-circle" font-scale="2"></b-icon>
        <b-icon v-else icon="x-circle" variant="danger" font-scale="2"></b-icon>
      </template>
    </table-render>

  </b-card>
</template>

<script>
import BaseServices from '@/store/services/index'
import { BIcon, BIconCheckCircle, BIconXCircle } from 'bootstrap-vue'
/* eslint-disable vue/no-unused-components */
export default {
  data() {
    return {
      fields: [],
      buttonSend: { icon: 'SearchIcon', color: 'warning', title: 'Buscar' },
      keyFormRender: 0,
      form: {},
      rows: {},
      schema: {},
      baseService: new BaseServices(this),
      msgRule: '',
      typeOfRule: '',
      typeOfRule2: '',
      message: '',
      deliveryId: '',
      ruleId: '',
      conditions: [],
      delivery: '',
      rule: '',
      loading: { first: false, second: false }
    }
  }, components: {
    BIcon, BIconCheckCircle, BIconXCircle
  }, methods: {
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldInput', name: 'deliveryId', label: this.$t('ID envío'), placeholder: 'Debe ingresar ID envío', validation: 'required' },
        { fieldType: 'FieldInput', name: 'ruleId', label: this.$t('ID regla'), placeholder: ' Debe ingresar ID regla', validation: 'required' },
        { name: 'cleanfilters', useSlot: true }
      ]
      this.schema = [
        { label: 'Variable', key: 'variable' },
        { label: 'Operador', key: 'operator' },
        { label: 'Condiciones', key: 'values', useSlot: true },
        { label: 'Valor Actual', key: 'verify', useSlot: true },
        { label: 'Verificación', key: 'verifyValue', useSlot: true }
      ]

    },
    async ok() {
      const checkForm = await this.$refs.formProveRules.onlyCheckForm()
      if (checkForm) {
        this.tryproverule(this.form)
        this.keyFormRender++
        this.blockButtonForSeconds(1)
      }

    },
    blockButtonForSeconds(seconds) {
      this.loading.second = true
      setTimeout(() => {
        this.loading.second = false
      }, seconds * 1000)
    },
    tryproverule() {
      const params = {}
      const queryParams = {
        deliveryId: Number(this.form.deliveryId),
        ruleId: Number(this.form.ruleId),
        ticketPayload: null,
        deliveryPayload: null
      }
      return this.baseService.callService('getProveRules', queryParams, params)
        .then(resp => {
          //datos del envío
          this.delivery = Number(this.form.deliveryId)
          this.rule = Number(this.form.ruleId)
          //respuesta de la regla
          this.msgRule = resp.message.msgRule
          this.typeOfRule = resp.message.typeOfRule.execution
          this.typeOfRule2 = resp.message.typeOfRule.type
          //condiciones
          this.conditions = resp.message.conditions.map(condition => ({
            variable: condition.variable,
            operator: condition.operator,
            values: condition.values,
            verify: condition.verify.value,
            verifyValue: condition.verify.verification
          }))
          this.rows = this.conditions
          return resp
        })
        .catch(err => {
          this.msgRule = 'No se encontro información'
          this.$alert('ID de envío o regla inválido')
          this.loading.first = false
          return this.msgRule
        })
    },
    cleanFilter() {
      this.delivery = ''
      this.rule = ''
      this.msgRule = ''
      this.typeOfRule = ''
      this.typeOfRule2 = ''
      this.message = ''
      this.deliveryId = ''
      this.ruleId = ''
      this.conditions = []
      this.delivery = ''
      this.form = {}
      this.rows = []
      this.keyFormRender++
      this.blockButtonForSeconds(1)
      this.$refs.formProveRules.reset()

    }
  },
  mounted() {
    this.setInitialData()
  }
}

</script>
<style></style>